import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const options = {
    theme: {
        themes: {
            light: {
                primary: '#ae3a7a',
                secondary: '#0a1032'
            }
        }
    }
};

export default new Vuetify({
    ...options
});
