export default [
    {
        path: '/admin',
        meta: { requiresAuth: true, requiresAdmin: true },
        component: {
            render (createElement) {
                return createElement('router-view');
            }
        },
        children: [
            {
                path: '',
                name: 'Admin',
                component: () =>
                    import(
                        /* webpackChunkName: "admin" */ '../views/admin/Dashboard.vue'
                    )
            },
            {
                path: 'events',
                name: 'Events',
                redirect: '/admin/events/lessons',
                component: {
                    render (createElement) {
                        return createElement('router-view');
                    }
                },
                children: [
                    {
                        path: 'lessons',
                        meta: {
                            title: 'Lektioner',
                            to: '/admin/events/lessons'
                        },
                        component: {
                            render (createElement) {
                                return createElement('router-view');
                            }
                        },
                        children: [
                            {
                                path: '',
                                name: 'Lessons',
                                component: () =>
                                    import(
                                        /* webpackChunkName: "lessons" */ '../views/admin/Lessons.vue'
                                    )
                            },
                            {
                                path: 'new',
                                name: 'CreateLesson',
                                component: () =>
                                    import(
                                        /* webpackChunkName: "lesson" */ '../views/admin/Lesson.vue'
                                    )
                            },
                            {
                                path: ':id',
                                name: 'EditLesson',
                                component: () =>
                                    import(
                                        /* webpackChunkName: "lesson" */ '../views/admin/Lesson.vue'
                                    )
                            }
                        ]
                    },

                    {
                        path: 'courses',
                        meta: { title: 'Kurser', to: '/admin/events/courses' },
                        component: {
                            render (createElement) {
                                return createElement('router-view');
                            }
                        },
                        children: [
                            {
                                path: '',
                                name: 'Courses',
                                component: () =>
                                    import(
                                        /* webpackChunkName: "courses" */ '../views/admin/Courses.vue'
                                    )
                            },
                            {
                                path: 'new',
                                name: 'CreateCourse',
                                component: () =>
                                    import(
                                        /* webpackChunkName: "course" */ '../views/admin/Course.vue'
                                    )
                            },
                            {
                                path: ':id',
                                name: 'EditCourse',
                                component: () =>
                                    import(
                                        /* webpackChunkName: "course" */ '../views/admin/Course.vue'
                                    )
                            }
                        ]
                    },

                    {
                        path: 'classrooms',
                        meta: {
                            title: 'Salar',
                            to: '/admin/events/classrooms'
                        },
                        component: {
                            render (createElement) {
                                return createElement('router-view');
                            }
                        },
                        children: [
                            {
                                path: '',
                                name: 'Classrooms',
                                component: () =>
                                    import(
                                        /* webpackChunkName: "classrooms" */ '../views/admin/Classrooms.vue'
                                    )
                            },
                            {
                                path: 'new',
                                name: 'CreateClassroom',
                                component: () =>
                                    import(
                                        /* webpackChunkName: "classroom" */ '../views/admin/Classroom.vue'
                                    )
                            },
                            {
                                path: ':id',
                                name: 'EditClassroom',
                                component: () =>
                                    import(
                                        /* webpackChunkName: "classroom" */ '../views/admin/Classroom.vue'
                                    )
                            }
                        ]
                    }
                ]
            },

            {
                path: 'lunch',
                name: 'Lunch',
                redirect: '/admin/lunch/menus',
                component: {
                    render (createElement) {
                        return createElement('router-view');
                    }
                },
                children: [
                    {
                        path: 'menus',
                        name: 'AdminLunchMenus',
                        meta: { title: 'Menyer', to: '/admin/lunch/menus' },
                        component: () =>
                            import(
                                /* webpackChunkName: "admin-lunch-menus" */ '../views/admin/LunchMenus.vue'
                            )
                    },
                    {
                        path: 'events',
                        meta: { title: 'Tider', to: '/admin/lunch/events' },
                        component: {
                            render (createElement) {
                                return createElement('router-view');
                            }
                        },
                        children: [
                            {
                                path: '',
                                name: 'LunchEvents',
                                component: () =>
                                    import(
                                        /* webpackChunkName: "lunch-events" */ '../views/admin/LunchEvents.vue'
                                    )
                            },
                            {
                                path: 'new',
                                name: 'CreateLunchEvent',
                                component: () =>
                                    import(
                                        /* webpackChunkName: "lunch-event" */ '../views/admin/LunchEvent.vue'
                                    )
                            },
                            {
                                path: ':id',
                                name: 'EditLunchEvent',
                                component: () =>
                                    import(
                                        /* webpackChunkName: "lunch-event" */ '../views/admin/LunchEvent.vue'
                                    )
                            }
                        ]
                    }
                ]
            },

            {
                path: 'reminders',
                meta: {
                    title: 'Kom ihåg',
                    to: '/admin/reminders'
                },
                component: {
                    render (createElement) {
                        return createElement('router-view');
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'AdminReminders',
                        component: () =>
                            import(
                                /* webpackChunkName: "admin-reminders" */ '../views/admin/Reminders.vue'
                            )
                    },
                    {
                        path: 'new',
                        name: 'CreateReminder',
                        component: () =>
                            import(
                                /* webpackChunkName: "reminder" */ '../views/admin/Reminder.vue'
                            )
                    },
                    {
                        path: ':id',
                        name: 'EditReminder',
                        component: () =>
                            import(
                                /* webpackChunkName: "reminder" */ '../views/admin/Reminder.vue'
                            )
                    }
                ]
            },

            {
                path: 'teachers',
                meta: {
                    title: 'Lärare',
                    to: '/admin/teachers'
                },
                component: {
                    render (createElement) {
                        return createElement('router-view');
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'Teachers',
                        component: () =>
                            import(
                                /* webpackChunkName: "teachers" */ '../views/admin/Teachers.vue'
                            )
                    },
                    {
                        path: 'new',
                        name: 'CreateTeacher',
                        component: () =>
                            import(
                                /* webpackChunkName: "teacher" */ '../views/admin/Teacher.vue'
                            )
                    },
                    {
                        path: ':id',
                        name: 'EditTeacher',
                        component: () =>
                            import(
                                /* webpackChunkName: "teacher" */ '../views/admin/Teacher.vue'
                            )
                    }
                ]
            },

            {
                path: 'classes',
                meta: {
                    title: 'Lärare',
                    to: '/admin/classes'
                },
                component: {
                    render (createElement) {
                        return createElement('router-view');
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'SchoolClasses',
                        component: () =>
                            import(
                                /* webpackChunkName: "schoolClasses" */ '../views/admin/SchoolClasses.vue'
                            )
                    },
                    {
                        path: 'new',
                        name: 'SchoolClassNew',
                        component: () =>
                            import(
                                /* webpackChunkName: "schoolClass" */ '../views/admin/SchoolClass.vue'
                            )
                    },
                    {
                        path: ':id',
                        name: 'SchoolClassEdit',
                        component: () =>
                            import(
                                /* webpackChunkName: "schoolClass" */ '../views/admin/SchoolClass.vue'
                            )
                    }
                ]
            },

            {
                path: 'users',
                meta: {
                    title: 'Användare',
                    to: '/admin/users'
                },
                component: {
                    render (createElement) {
                        return createElement('router-view');
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'Users',
                        component: () =>
                            import(
                                /* webpackChunkName: "users" */ '../views/admin/Users.vue'
                            )
                    },
                    {
                        path: 'new',
                        name: 'CreateUser',
                        component: () =>
                            import(
                                /* webpackChunkName: "user" */ '../views/admin/User.vue'
                            )
                    },
                    {
                        path: ':id',
                        name: 'EditUser',
                        component: () =>
                            import(
                                /* webpackChunkName: "user" */ '../views/admin/User.vue'
                            )
                    }
                ]
            }
        ]
    }
];
