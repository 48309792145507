import Vue from 'vue';
import VueRouter from 'vue-router';
import adminRoutes from './adminRoutes';
import authRoutes from './authRoutes';
import studentRoutes from './studentRoutes';

Vue.use(VueRouter);

const routes = [
    ...studentRoutes,

    ...adminRoutes,

    ...authRoutes,

    {
        path: '/admin/*',
        beforeEnter () {
            window.location = '/admin';
        }
    },

    {
        path: '*',
        beforeEnter () {
            window.location = '/';
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }),
    routes
});

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token');
    let user = window.localStorage.getItem('user');

    user = user ? JSON.parse(user) : null;

    const isAuthed = !!token;
    const isAdmin = !!user?.roles?.find(role => role.name === 'admin');
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
    const requiresStudent = to.matched.some(
        record => record.meta.requiresStudent
    );
    const requiresGuest = to.matched.some(record => record.meta.requiresGuest);

    if (to.name === from.name) {
        return;
    }

    if (requiresAuth && !isAuthed) {
        next({ path: '/login' });
    } else if (requiresAdmin && !isAdmin) {
        next({ path: '/' });
    } else if (requiresGuest && isAuthed) {
        next({ path: '/' });
    } else if (requiresStudent && isAdmin) {
        next({ path: '/admin' });
    } else {
        next();
    }
});

export default router;
