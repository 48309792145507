<template>
    <nav
        v-if="links && links.length > 0"
        class="adminTop bg-white relative"
    >
        <ul class="flex -ml-8">
            <li
                v-for="link in links"
                :key="link.label"
                class="item mx-8"
            >
                <router-link
                    class="link uppercase tracking-wider font-bold text-xl py-6 block"
                    :to="link.meta.to"
                >
                    {{ link.meta.title }}
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    computed: {
        links () {
            const routes = this.$router.options.routes;
            const adminRoutes = routes?.find(route => route.path === '/admin')
                ?.children;

            const childs = adminRoutes?.find(
                route => route.name === this.$route.matched[1]?.name
            )?.children;

            if (!childs || !childs[0] || !childs[0]?.meta?.to) {
                return false;
            }

            return childs;
        }
    }
};
</script>

<style lang="postcss" scoped>
.adminTop {
    padding-top: 3px;
    padding: 0 4rem;
    z-index: 10;
    @media (--laptop) {
        padding: 0 3.5rem;
    }
    @media (--largeTablet) {
        padding: 0 3rem;
        border-top: 1px solid #ddd;
    }
    @media (--mediumTablet) {
        padding: 0 2.5rem;
    }
}

.link {
    border-bottom: 3px solid transparent;
    @media (--largeTablet) {
        padding: 1rem 0 0.8rem;
    }
    &.router-link-active {
        border-color: var(--primaryColor);
    }
}
</style>
