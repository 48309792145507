<template>
    <div>
        <main class="main">
            <TopBar :user="authUser" />
            <AdminTop v-if="isAdmin" />

            <div class="inner">
                <UserTop
                    v-if="!isAdmin"
                    :name="authUser.name"
                />

                <slot />
            </div>
        </main>

        <Navigation />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Navigation from './Navigation';
import UserTop from './UserTop';
import TopBar from './TopBar';
import AdminTop from './AdminTop';

export default {
    components: {
        Navigation,
        UserTop,
        TopBar,
        AdminTop
    },

    computed: {
        ...mapGetters('auth', ['authUser', 'isAdmin'])
    }
};
</script>

<style lang="postcss" scoped>
.main {
    padding-left: 15rem;
    @media (--largeTablet) {
        padding-left: 0;
    }
}

.inner {
    width: 1770px;
    max-width: 100%;
    margin: 0 auto;
    padding: 5rem 4rem;
    @media (--laptop) {
        padding: 4.5rem 3.5rem;
    }
    @media (--largeTablet) {
        padding: 4.5rem 3rem;
    }
    @media (--mediumTablet) {
        padding: 4.2rem 2.5rem;
    }
}
</style>
