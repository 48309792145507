import { render, staticRenderFns } from "./UserTop.vue?vue&type=template&id=5a06ea77&scoped=true&"
import script from "./UserTop.vue?vue&type=script&lang=js&"
export * from "./UserTop.vue?vue&type=script&lang=js&"
import style0 from "./UserTop.vue?vue&type=style&index=0&id=5a06ea77&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a06ea77",
  null
  
)

export default component.exports