export default [
    {
        path: '/',
        name: 'Home',
        meta: { requiresAuth: true, requiresStudent: true },
        component: () =>
            import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/kom-ihag',
        name: 'Reminders',
        meta: { requiresAuth: true, requiresStudent: true },
        component: () =>
            import(/* webpackChunkName: "reminders" */ '../views/Reminders.vue')
    },
    {
        path: '/lunchmenyer',
        name: 'LunchMenus',
        meta: { requiresAuth: true, requiresStudent: true },
        component: () =>
            import(
                /* webpackChunkName: "lunch-menus" */ '../views/LunchMenus.vue'
            )
    },
    {
        path: '/mentor',
        name: 'Mentor',
        meta: { requiresAuth: true, requiresStudent: true },
        component: () =>
            import(/* webpackChunkName: "mentor" */ '../views/Mentor.vue')
    },
    {
        path: '/sjukanmalan',
        name: 'SickReport',
        meta: { requiresAuth: true, requiresStudent: true },
        component: () =>
            import(
                /* webpackChunkName: "sick-report" */ '../views/SickReport.vue'
            )
    }
];
