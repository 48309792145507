export default [
    {
        path: '/login',
        name: 'Login',
        meta: { requiresGuest: true },
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/auth/Login.vue')
    },

    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        meta: { requiresGuest: true },
        component: () =>
            import(
                /* webpackChunkName: "forgot-password" */ '../views/auth/ForgotPassword.vue'
            )
    },

    {
        path: '/reset-password/:token',
        name: 'ResetPassword',
        meta: { requiresGuest: true },
        component: () =>
            import(
                /* webpackChunkName: "reset-password" */ '../views/auth/ResetPassword.vue'
            )
    }
];
