<template>
    <aside class="navigation text-center">
        <div class="inner ab100">
            <button
                class="logoutButton"
                type="button"
                @click="logout"
            >
                <Avatar
                    :image="authUser.image"
                    :name="authUser.name"
                />

                <span class="logoutLabel">Logga ut</span>
            </button>

            <nav
                v-if="!isAdmin"
                class="menu"
            >
                <ul>
                    <NavItem
                        v-for="item in navItems"
                        :key="item.label"
                        :label="item.label"
                        :icon="item.icon"
                        :url="item.url"
                        :class="item.classes"
                    />
                </ul>
            </nav>

            <nav
                v-if="isAdmin"
                class="menu"
            >
                <ul>
                    <NavItem
                        v-for="item in adminNavItems"
                        :key="item.label"
                        :label="item.label"
                        :icon="item.icon"
                        :url="item.url"
                        small
                    />
                </ul>
            </nav>
        </div>
    </aside>
</template>

<script>
import NavItem from './NavItem';
import Avatar from '../ui/Avatar';
import { mapGetters } from 'vuex';

export default {
    components: {
        NavItem,
        Avatar
    },

    data: () => ({
        navItems: [
            {
                label: 'Schema',
                url: '/',
                icon: 'ClockIcon'
            },

            {
                label: 'Kom ihåg',
                url: '/kom-ihag',
                icon: 'BellIcon',
                classes: 'show-largeTablet'
            },

            {
                label: 'Lunchmeny',
                url: '/lunchmenyer',
                icon: 'ClipboardIcon'
            },

            {
                label: 'Mentor',
                url: '/mentor',
                icon: 'SupportIcon'
            },

            {
                label: 'Sjukanmälan',
                url: '/sjukanmalan',
                icon: 'AnnotationIcon'
            }
        ],

        adminNavItems: [
            {
                label: 'Scheman',
                url: '/admin',
                icon: 'ClockIcon'
            },

            {
                label: 'Lektioner',
                url: '/admin/events',
                icon: 'FolderIcon'
            },

            {
                label: 'Kom ihåg',
                url: '/admin/reminders',
                icon: 'BellIcon'
            },

            {
                label: 'Luncher',
                url: '/admin/lunch',
                icon: 'ClipboardIcon'
            },

            {
                label: 'Lärare',
                url: '/admin/teachers',
                icon: 'SupportIcon'
            },

            {
                label: 'Klasser',
                url: '/admin/classes',
                icon: 'UsersIcon'
            },

            {
                label: 'Användare',
                url: '/admin/users',
                icon: 'UserIcon'
            }
        ]
    }),

    computed: {
        ...mapGetters('auth', ['authUser', 'isAdmin'])
    },

    methods: {
        logout () {
            this.$store.dispatch('auth/logout');
        }
    }
};
</script>

<style lang="postcss" scoped>
.navigation {
    position: fixed;
    left: 0;
    top: 0;
    width: 15rem;
    height: 100%;
    background-color: #fff;
    box-shadow: 2.5px 4.3px 43px 0 rgba(0, 0, 0, 0.05);
    @media (--largeTablet) {
        width: 100%;
        height: auto;
        top: auto;
        bottom: 0;
        z-index: 10;
        box-shadow: 0 0.43rem 4.3rem 0 rgba(0, 0, 0, 0.05);
    }
}

.inner {
    width: calc(100% + 3px);
    padding: 4rem 3rem;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    @media (--largeTablet) {
        position: relative;
        width: 100%;
        height: auto;
        overflow: hidden;
        padding: 0;
    }
}

.logoutButton {
    @media (--largeTablet) {
        display: none;
    }
}

.logoutButton:hover {
    color: var(--primaryColor);
}

.avatar {
    margin-bottom: 1rem;
}

.logoutLabel {
    font-size: 1.1rem;
    font-weight: bold;
    text-transform: uppercase;
}

.menu {
    margin-top: 4rem;
    @media (--largeTablet) {
        margin-top: 0;
    }
}

.menu ul {
    @media (--largeTablet) {
        display: flex;
    }
}
</style>
