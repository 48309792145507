<template>
    <li
        :class="{ 'isSmall': small }"
        class="navItem"
    >
        <router-link
            :to="url"
            :class="{ 'isHome': url === '/' || url === '/admin' }"
            class="link block relative"
        >
            <span class="icon">
                <component :is="icon" />
            </span>

            <span class="label">
                {{ label }}
            </span>

            <span class="line h-full absolute"></span>
        </router-link>
    </li>
</template>

<script>
import UserIcon from '@/assets/images/user.svg';
import CalendarIcon from '@/assets/images/calendar.svg';
import AnnotationIcon from '@/assets/images/annotation.svg';
import SupportIcon from '@/assets/images/support.svg';
import ClipboardIcon from '@/assets/images/clipboard.svg';
import ClockIcon from '@/assets/images/clock.svg';
import BookIcon from '@/assets/images/book.svg';
import BellIcon from '@/assets/images/bell.svg';
import FolderIcon from '@/assets/images/folder.svg';
import UsersIcon from '@/assets/images/users.svg';

export default {
    components: {
        UserIcon,
        CalendarIcon,
        AnnotationIcon,
        SupportIcon,
        ClipboardIcon,
        ClockIcon,
        BookIcon,
        BellIcon,
        FolderIcon,
        UsersIcon
    },

    props: {
        icon: {
            type: String,
            required: true
        },

        url: {
            type: String,
            required: true
        },

        label: {
            type: String,
            required: true
        },

        small: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="postcss" scoped>
.navItem {
    margin-bottom: 3rem;
    @media (--largeTablet) {
        margin-bottom: 0;
        width: 25%;
    }
    &:last-child {
        margin-bottom: 0;
    }

    &.isSmall {
        @media (--largeTablet) {
            width: calc(100% / 6);
        }
    }
}

.link {
    color: #9d9d9d;
    padding: 1rem 0;
}

.link.router-link-exact-active,
.link.router-link-active:not(.isHome) {
    color: var(--primaryTextColor);

    & .icon {
        color: var(--primaryColor);
    }

    & .line {
        opacity: 1;
    }
}

.link:hover {
    color: var(--primaryTextColor);
}

.label {
    font-size: 1.5rem;
    font-weight: bold;
    @media (--mobile) {
        font-size: 1.2rem;
    }
}

.icon {
    width: 2.8rem;
    height: 2.8rem;
    display: block;
    margin: 0 auto 0.4rem;
    @media (--mobile) {
        width: 2.6rem;
        height: 2.6rem;
        margin-bottom: 0.3rem;
    }
}

.line {
    right: 0;
    top: 0;
    width: 3px;
    margin-right: -3rem;
    background-color: var(--primaryColor);
    opacity: 0;
    @media (--largeTablet) {
        width: 100%;
        height: 2px;
        margin-right: 0;
    }
}
</style>
