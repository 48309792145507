<template>
    <div class="userTop flex justify-between">
        <div class="left">
            <h2 class="heading">
                Hej {{ name }}
            </h2>

            <Content>
                <p>Här hittar du all information om din skolvecka.</p>
            </Content>
        </div>

        <div class="right">
            <Clock />
        </div>
    </div>
</template>

<script>
import Clock from '@/components/ui/Clock';
import Content from '@/components/ui/Content';

export default {
    components: {
        Clock,
        Content
    },

    props: {
        name: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="postcss" scoped>
.userTop {
    margin-bottom: 5rem;
    @media (--largeTablet) {
        display: none;
    }
}

.left {
    margin-right: 3rem;
    @media (--smallTablet) {
        margin-right: 0;
        width: 100%;
    }
}

.right {
    @media (--smallTablet) {
        display: none;
    }
}

.heading {
    color: var(--secondaryColor);
    font-size: 3.8rem;
    @media (--mobile) {
        font-size: 3rem;
        margin-bottom: 1rem;
    }
}
</style>
