<template>
    <div
        id="app"
        class="v-application--is-ltr w-full flex relative"
        data-app
    >
        <div
            v-if="!loggingOut"
            class="relative w-full overflow-hidden"
        >
            <Main v-if="authUser && loggedIn">
                <transition
                    name="fade"
                    mode="out-in"
                >
                    <router-view />
                </transition>
            </Main>

            <transition
                v-else
                name="fade"
                mode="out-in"
            >
                <router-view />
            </transition>
        </div>
    </div>
</template>

<script>
import AuthService from '@/services/AuthService';
import Main from '@/components/layout/Main';
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
    components: {
        Main
    },

    data: () => ({
        loggingOut: false
    }),

    computed: {
        ...mapGetters('auth', ['authUser', 'loggedIn'])
    },

    watch: {
        loggedIn () {
            if (this.loggedIn) {
                return;
            }

            this.loggingOut = true;

            setTimeout(() => {
                this.loggingOut = false;
            }, 50);
        }
    },

    async created () {
        let user = window.localStorage.getItem('user');

        user = user ? JSON.parse(user) : null;

        if (this.loggedIn && user) {
            this.$store.commit('auth/SET_USER', user);
        }

        axios.defaults.withCredentials = true;
        await axios.get('/api/sanctum/csrf-cookie');

        if (this.loggedIn) {
            const res = await AuthService.currentUser();

            if (res?.data?.user) {
                this.$store.commit('auth/SET_USER', res?.data?.user);
            }
        }
    }
};
</script>

<style>
#app {
    min-height: 100%;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}
</style>
