<template>
    <div
        :class="{ 'hasImage': image }"
        class="avatar rounded-full overflow-hidden flex justify-center items-center"
    >
        <img
            v-if="image"
            class="objectFitCover"
            :src="image"
            alt=""
        >

        <span
            v-else
            class="initials"
        >
            {{ initials }}
        </span>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        },

        image: {
            type: String,
            default: null
        }
    },

    computed: {
        initials () {
            return this.name?.split(' ').map((letters) => letters[0]).slice(0, 2).join(' ');
        }
    }
};
</script>

<style lang="postcss" scoped>
.avatar {
    font-size: 1rem;
    width: 7.2em;
    height: 7.2em;
    background-color: var(--secondaryColor);
    flex: none;
    &.hasImage {
        background-color: transparent;
    }
}

.initials {
    font-size: 2em;
    font-weight: bold;
    color: #ffff;
}
</style>
