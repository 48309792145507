<template>
    <nav class="topBar bg-white flex items-center justify-between relative">
        <div class="user flex items-center">
            <Avatar
                :name="user.name"
                :image="user.image"
            />

            <span class="name">
                {{ user.name }}
            </span>
        </div>

        <button
            type="button"
            class="logout inline-flex items-center"
            @click="logout"
        >
            Logga ut

            <LogoutIcon class="logoutIcon ml-3 flex-shrink-0" />
        </button>
    </nav>
</template>

<script>
import Avatar from '@/components/ui/Avatar';
import LogoutIcon from '@/assets/images/logout.svg';
import { mapGetters } from 'vuex';

export default {
    components: {
        Avatar,
        LogoutIcon
    },

    props: {
        user: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters('auth', ['authUser', 'isAdmin'])
    },

    methods: {
        logout () {
            this.$store.dispatch('auth/logout');
        }
    }
};
</script>

<style lang="postcss" scoped>
.topBar {
    padding: 0.5rem 3rem;
    box-shadow: 0 0.43rem 4.3rem 0 rgba(0, 0, 0, 0.05);
    display: none;
    @media (--largeTablet) {
        display: flex;
    }
    @media (--mediumTablet) {
        padding: 0.4rem 2.5rem;
    }
}

.avatar {
    font-size: 0.52rem;
}

.name {
    color: #0a1032;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.03rem;
    margin-left: 1rem;
}

.logout {
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0.03rem;
    text-transform: uppercase;
    &:hover,
    &:hover .logoutIcon {
        color: var(--primaryColor);
    }
}

.logoutIcon {
    width: 2rem;
    height: 2rem;
    color: #0a1032;
    transition: var(--normalTrans);
}
</style>
