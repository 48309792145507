<template>
    <div class="clock">
        <div class="top flex items-center">
            <div class="left flex justify-center">
                <ClockIcon class="icon" />
            </div>

            <div class="right flex-grow">
                <p class="time">
                    {{ time }}
                </p>
            </div>
        </div>

        <div class="bottom flex">
            <div class="left"></div>

            <div class="right flex-grow text-center">
                <p class="place">
                    Ronneby, Sverige
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import ClockIcon from '@/assets/images/clock.svg';

export default {
    components: {
        ClockIcon
    },

    data: () => ({
        locale: 'sv-SE',
        dateTime: null
    }),

    computed: {
        time () {
            return this.dateTime.toLocaleTimeString(this.locale, {
                hour: '2-digit',
                minute: '2-digit'
            });
        }
    },

    created () {
        this.updateTime();
        this.$options.interval = setInterval(this.updateTime, 1000);
    },

    beforeDestroy () {
        clearInterval(this.$options.interval);
    },

    methods: {
        updateTime () {
            this.dateTime = new Date();
        }
    }
};
</script>

<style lang="postcss" scoped>
.clock {
    color: var(--secondaryColor);
}

.left {
    width: 4.5rem;
    margin-right: 0.5rem;
}

.icon {
    width: 3.8rem;
    height: 3.8rem;
    color: var(--primaryColor);
}

.time {
    font-size: 4.8rem;
    letter-spacing: 0.096rem;
    line-height: 1.2;
    margin-bottom: 0;
}

.place {
    font-size: 1.4rem;
    letter-spacing: 0.028rem;
}
</style>
